<template>
  <section>
    <TopBar/>
    <Header/>
    <NavBar/>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background" v-if="ann">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Announcements</li><span class="arrow">></span>
                  <li>{{ann.annTitle}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <div v-if="error">{{error}}</div>
                    <div  v-if="ann" class="post">
                        <h3>{{ann.annTitle}}</h3>
                        <h5>Posted on : <span> {{ann.date.toDate().toLocaleString('en-us',{dateStyle:'long'})}} </span></h5>     
                        <p class="pre" v-html="ann.annHTML"></p>
                    </div>
                </div>
              
          <div class="sidebar">
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script>

import Footer from '../../components/page/Footer.vue'
import getAnn from "@/composables/getAnn"
import TopBar from '../../components/page/TopBar.vue';
import Header from '../../components/page/Header.vue';
import NavBar from '../../components/page/NavBar.vue';
import RecentPosts from '../../components/page/RecentPosts.vue';

export default {
    name: 'ViewAnnouncement',    
    props: ["id"],  
    components: { Footer, TopBar, Header, NavBar, RecentPosts },
    setup(props){
        const { ann, error } = getAnn(props.id);

        return {
            ann, error
      }
  }
}
</script>

<style scoped>

.page :deep(li){
  margin-left: 2rem;
}
.page :deep(h3){
    margin-top: 2rem;
}
.page :deep(h5){
    padding-top: 0.5rem;
}
.page :deep(h5 span){
    font-weight: 400;
}
.page :deep(img){
    width: 100%;
}
@media (min-width:768px) {
    .page :deep(h3){
    margin-top: 1rem;
}
}
</style>